<template>
  <div id="invoice-page">
    <!-- <vue-qz-tray> -->
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
        <!-- <vue-qz-tray-action></vue-qz-tray-action> -->
      </div>
    </div>

    <vs-tabs>
      <!-- <vs-tab label="Print Options"> -->
      <!-- <div class="tab-text"> -->
      <!-- <vue-qz-tray-connect></vue-qz-tray-connect>

            <vue-qz-tray-printers v-show="false"></vue-qz-tray-printers>

            <vue-qz-tray-input
              v-show="false"
              :pages="receiptData"
            ></vue-qz-tray-input> -->
      <!-- </div> -->
      <!-- </vs-tab> -->
      <vs-tab label="Purchase Receipt">
        <vx-card id="invoice-container">
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-full md:w-1/2 mt-base">
              <img
                :src="$root.logo ? $root.logo : '@/assets/images/logo/logo.png'"
                height="180px"
                alt="logo"
              />
            </div>
            <div v-show="purchase" class="vx-col w-full md:w-1/2 text-right">
              <h1>Purchase Receipt</h1>
              <div class="invoice__invoice-detail mt-6">
                <h6>RECEIPT NO.</h6>
                <p>{{ purchaseData ? purchaseData.date_created : '' }}</p>

                <h6 class="mt-4">RECEIPT DATE</h6>
                <p>
                  {{
                    purchaseData
                      ? parseDate(getDate(purchaseData.date_created))
                      : ''
                  }}
                </p>
                <h6 class="mt-4">STATUS</h6>
                <p
                  :style="[
                    purchaseData && purchaseData.fulfilled
                      ? { color: 'green' }
                      : { color: 'red' },
                    { fontWeight: '800' },
                  ]"
                >
                  {{
                    purchaseData && purchaseData.fulfilled
                      ? 'FULFILLED'
                      : 'PENDING'
                  }}
                </p>
              </div>
            </div>
            <div v-if="supplierData" class="vx-col w-full md:w-1/2 mt-12">
              <h5>
                {{ supplierData.supplier_name }}
              </h5>
              <div class="invoice__company-info my-2">
                <p>{{ supplierData.supplier_address }}</p>
              </div>
              <div class="invoice__company-contact">
                <p class="flex items-center justify-start">
                  <feather-icon
                    icon="MailIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">N/A</span>
                </p>
                <p class="flex items-center justify-start">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">{{ supplierData.supplier_phone }}</span>
                </p>
                <p class="flex items-center justify-start">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">{{
                    supplierData.supplier_alt_phone
                  }}</span>
                </p>
              </div>
            </div>
            <div v-else class="vx-col w-full md:w-1/2 mt-12"></div>
            <div
              v-if="$root.businessData"
              class="vx-col w-full md:w-1/2 text-right mt-12"
            >
              <h5>
                {{ $root.businessData.data.business_name }}
                {{ branchData ? `- ${branchData.branch_name}` : '' }}
              </h5>
              <div class="invoice__company-info my-4">
                <p>{{ $root.businessData.data.postal_address }}</p>
                <p>{{ $root.businessData.data.postal_city }}</p>
              </div>
              <div class="invoice__company-contact">
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="MailIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">N/A</span>
                </p>
                <p class="flex items-center justify-end">
                  <feather-icon
                    icon="PhoneIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                  <span class="ml-2">{{
                    $root.businessData.data.main_phone
                  }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="p-base">
            <vs-table :data="products" hover-fla>
              <template slot="thead">
                <vs-th>ITEM</vs-th>
                <vs-th>COST PRICE</vs-th>
                <vs-th>RETAIL PRICE</vs-th>
                <vs-th>QUANTITY RESTOCKED</vs-th>
                <vs-th>TOTAL</vs-th>
              </template>

              <template>
                <vs-tr v-for="(tr, index) in products" :key="index">
                  <vs-td :data="tr.product_name"
                    >{{ tr.product_code }} {{ tr.product_name }}</vs-td
                  >
                  <vs-td :data="tr.cost_price">{{
                    Number(parseInt(tr.cost_price)).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}</vs-td>
                  <vs-td :data="tr.cost_price">{{
                    Number(parseInt(tr.retail_price)).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}</vs-td>
                  <vs-td
                    :data="
                      'newStock' in tr ? tr.newStock[purchaseData.branch] : 0
                    "
                    >{{
                      'newStock' in tr
                        ? tr.newStock[purchaseData.branch]
                        : 'N/A'
                    }}</vs-td
                  >
                  <vs-td>
                    {{
                      'newStock' in tr
                        ? Number(
                            parseInt(
                              tr.newStock[purchaseData.branch] * tr.cost_price,
                            ),
                          ).toLocaleString('en-KE', {
                            style: 'currency',
                            currency: $root.businessData.data.currency,
                          })
                        : '--'
                    }}</vs-td
                  >
                </vs-tr>
              </template>
            </vs-table>

            <vs-table
              v-if="purchaseData"
              :data="purchaseData"
              hover-flat
              class="w-1/2 ml-auto mt-4"
            >
              <vs-tr>
                <th>TOTAL</th>
                <td>
                  {{
                    Number(parseInt(purchaseData.amount)).toLocaleString(
                      'en-KE',
                      {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      },
                    )
                  }}
                </td>
              </vs-tr>
            </vs-table>
          </div>

          <div class="invoice__footer text-right p-base">
            <p class="mb-4" />
            <p class="mb-4"></p>
          </div>
        </vx-card>
      </vs-tab>
    </vs-tabs>
    <!-- </vue-qz-tray> -->
  </div>
</template>

<script>
import idb from '@/db/idb';

export default {
  components: {},
  data() {
    return {
      purchase: {
        id: 0,
        amount: 0,
        date: Date.now(),
        receipt_number: '',
        products: [],
      },
    };
  },
  pouch: {
    purchaseData() {
      return {
        database: 'express',
        selector: { type: 'purchase', _id: this.$route.params.id },
        first: true,
      };
    },
    branchData() {
      if (this.purchaseData) {
        return {
          database: 'express',
          selector: { type: 'branch', _id: this.purchaseData.branch },
          first: true,
        };
      }
      return {};
    },
    supplierData() {
      if (this.purchaseData) {
        return {
          database: 'express',
          selector: { type: 'supplier', _id: this.purchaseData.supplier },
          first: true,
        };
      }
      return {};
    },
  },
  computed: {
    products() {
      return this.purchaseData && this.purchaseData.products
        ? this.purchaseData.products
        : [];
    },
    receiptData() {
      return [
        '\x1B' + '\x40',
        '\x1B' + '\x61' + '\x31',
        '\x1B' + '\x45' + '\x0D',
        //   { type: 'raw', format: 'image', flavor: 'file', data: '../../../assets/images/logo/logo.png', options: { language: "ESCPOS", dotDensity: 'double' } },
        //  '\x0A',
        'COMPANY NAME' + '\x0A',
        '\x1B' + '\x45' + '\x0A',
        'ADDRESS' + '\x0A',
        'CITY' + '\x0A',
        this.companyDetails.mobile + '\x0A',
        '\x0A',
        this.purchase.date + '\x0A',
        '\x0A',
        'Purchase #' + this.$route.params.id + '\x0A',
        '\x0A',
        '\x1B' + '\x61' + '\x30',
        '------------------------------------------' + '\x0A',
        '\x1B' + '\x45' + '\x0A',
        '\x1B' + '\x61' + '\x30', // left align
        'PRODUCT             QTY   RATE     AMOUNT' + '\x0A',
        '------------------------------------------' + '\x0A',
        this.receiptProduct,
        '\x0A',
        '- - - - - - - - - - - - - - - - - - - - - ' + '\x0A',
        '\x1B' + '\x45' + '\x0D', // bold on
        'TOTAL                         KES ' +
          Number(parseInt(this.purchase.amount)).toLocaleString() +
          '\x0A',
        '\x1B' + '\x45' + '\x0A',
        'ITEMS PURCHASED: ' + this.purchase.products.length + '\x0A',
        '\x1B' + '\x61' + '\x31',
        '\x1B' + '\x45' + '\x0D',
        '------------------------------------------' + '\x0A',
        '\x1B' + '\x45' + '\x0D',
        '\x1D' +
          'h' +
          String.fromCharCode(80) +
          '\x1D' +
          'f' +
          String.fromCharCode(0) +
          '\x1D' +
          'k' +
          String.fromCharCode(69) +
          String.fromCharCode(this.purchase.id) +
          this.purchase.id +
          String.fromCharCode(0) +
          '\x0A',
        '\x0A',
        '\x1B' + '\x4D' + '\x30',
        'Substitute for Value & Quality' + '\x0A',
        '\x1B' + '\x4D' + '\x30',
        '\x1B' + '\x4D' + '\x31',
        'Thank you !.. Visit Again!..' + '\x0A',
        '\x1B' + '\x4D' + '\x30',
        '\x1B' + '\x45' + '\x0D',
        '------------------------------------------' + '\x0A',
        '\x1B' + '\x45' + '\x0D',
        atob('UE9TIGJ5IEdhbGlsZW8gQ29tcHV0aW5n') + '\x0A',
        '\x1B' + '\x4D' + '\x31',
        '+254 710 710 037' + '\x0A',
        '\x1B' + '\x4D' + '\x30',
        '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',
        '\x1B' + '\x69',
        '\x10' + '\x14' + '\x01' + '\x00' + '\x05',
      ];
    },
    receiptProduct() {
      var data = '';
      for (var i = 0; i < this.purchase.products.length; i++) {
        data +=
          this.purchase.products[i]['product']['product_name'].substring(
            0,
            18,
          ) +
          this.returnSpace(
            this.purchase.products[i]['product']['product_name'].substring(
              0,
              18,
            ),
            20,
          ) +
          this.purchase.products[i]['quantity'] +
          this.returnSpace(
            this.purchase.products[i]['quantity'].toString(),
            7,
          ) +
          this.purchase.products[i]['product']['wholesale_price'] +
          this.returnSpace(
            '0' +
              this.purchase.products[i]['product'][
                'wholesale_price'
              ].toString(),
            9,
          ) +
          (
            this.purchase.products[i]['product']['wholesale_price'] *
            this.purchase.products[i]['quantity']
          ).toString() +
          '\x0A';
      }
      return data;
    },
    customerOptions() {
      return this.$store.state.customers.customers;
    },
    paymentMethods() {
      return this.$store.state.finance.paymentMethods;
    },
    employeeOptions() {
      return this.$store.state.employees.employees;
    },
  },
  mounted() {
    this.$emit('setAppClasses', 'invoice-page');
  },
  async created() {
    // this.purchase.id = this.$route.params.id
    // this.$store.dispatch("products/fetchProducts")
    // this.$store.dispatch("purchases/fetchPurchase", this.purchase.id)
    //   .then(response => {
    //     this.showMessage('icon-check-circle', 'success', 'Receipt Generated Successfully', 'You have successfully generated a receipt for purchase #'+this.purchase.id)
    //         console.log(response.data)
    //         this.purchase.amount = response.data.amount
    //         this.purchase.date = this.$moment(response.data.date).format("dd DD MMM YYYY, h:mm:ss a")
    //         this.purchase.receipt_number = response.data.receipt_number
    //         this.$store.dispatch("purchases/fetchPurchaseItems", this.purchase.id)
    //             .then(res => {
    //                 var items = res.data
    //                 for(var i = 0; i < items.length; i++) {
    //                     var obj = {
    //                         "purchase": items[i]['fields']['purchase'],
    //                         "product": this.$store.state.products.products.find((x) => x.id == items[i]['fields']['product']),
    //                         "quantity": items[i]['fields']['quantity'],
    //                         "cost_price": items[i]['fields']['cost_price'],
    //                         "retail_price": items[i]['fields']['retail_price'],
    //                     }
    //                     this.purchase.products.push(obj)
    //                 }
    //                 console.log(this.purchase.products)
    //             })
    //             .catch(err => {
    //                 console.error(err)
    //                 this.showMessage('icon-error-circle', 'danger', 'Error', err)
    //               })
    //         console.log(this.purchase)
    //   })
    //   .catch(err => {
    //     console.error(err)
    //     this.showMessage('icon-error-circle', 'danger', 'Error', err)
    //   })
  },
  methods: {
    parseDate(date) {
      return this.$moment(date).format('ddd DD MMM YYYY, h:mm:ss a');
    },
    getDate(x) {
      return this.$moment(x).format('DD MMM YYYY hh:mm:ss');
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    returnSpace(cd, length) {
      var space = '';
      var count = length - cd.length;
      for (var i = 0; i < count; i++) {
        space += ' ';
      }
      console.log('space length ' + count);
      return space;
    },
    showMessage(icon, color, title, message) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: icon,
        color: color,
      });
    },
    printInvoice() {
      // window.print();
    },
  },
};
</script>

<style lang="scss">
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
</style>
